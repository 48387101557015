import { handleActions } from 'redux-actions';
import { actions } from './actions';
export { actions };

export { sagas } from './sagas';
import * as selectors from './selectors';
export { selectors };

const defaultState = {
  filters: {
    period: 'FIVEYEARS',
    startDate: null,
    endDate: null,
    excludedSsids: [],
    externalReporting: ['showInternalPortfolio', 'showExternalPortfolio'],
    excludedTaxClasses: [],
    excludedInvestmentAccounts: [],
  },
  filterValues: {
    period: [
      'THISMONTH',
      'THISYEAR',
      'TWELVEMONTHS',
      'THREEYEARS',
      'FIVEYEARS',
      'TENYEARS',
    ],
    externalReporting: ['showInternalPortfolio', 'showExternalPortfolio'],
  },
  activeStrategyId: null,
  defaultFilters: null,
  updatedAt: null,
  calculatedAt: null,
  initialized: false,
  debounceTime: 600,
};

const filterExternalReportingHandler = (payload, state) => {
  const { externalReporting } = state.filters;
  let result;
  if (externalReporting.includes(payload)) {
    result = externalReporting.filter((item) => item !== payload);
  } else {
    result = [...externalReporting, payload];
  }

  return result;
};

const filterTaxClassHandler = (payload, state) => {
  const { excludedTaxClasses } = state.filters;
  let result;

  if (excludedTaxClasses.includes(payload)) {
    result = excludedTaxClasses.filter((item) => item !== payload);
  } else {
    result = [...excludedTaxClasses, payload];
  }

  return result;
};

const filterInvestmentAccountHandler = (payload, state) => {
  const { excludedInvestmentAccounts } = state.filters;

  let result;

  const isArray = Array.isArray(payload);
  if (isArray) {
    if (
      excludedInvestmentAccounts.some((excludedAccount) =>
        payload.includes(excludedAccount)
      )
    ) {
      result = excludedInvestmentAccounts.filter(
        (item) => !payload.includes(item)
      );
    } else {
      result = [...excludedInvestmentAccounts, ...payload];
    }
  } else {
    if (excludedInvestmentAccounts.includes(payload)) {
      result = excludedInvestmentAccounts.filter((item) => item !== payload);
    } else {
      result = [...excludedInvestmentAccounts, payload];
    }
  }

  return result;
};

export const reducer = handleActions(
  {
    [actions.filterPeriod]: (state, { payload }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          period: payload.period,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      };
    },

    // Note that setFilters action will overwrite all other filters and explicitly set the once you
    // pass in. As opposed to the specific filter actions that will toggle the value you plass in.
    [actions.setFilters]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        excludedSsids: payload?.excludedSsids
          ? payload?.excludedSsids
          : state.filters.excludedSsids,
        externalReporting: payload?.externalReporting
          ? payload?.externalReporting
          : state.filters.externalReporting,
        excludedTaxClasses: payload?.excludedTaxClasses
          ? payload?.excludedTaxClasses
          : state.filters.excludedTaxClasses,
        excludedInvestmentAccounts: payload?.excludedInvestmentAccounts
          ? payload?.excludedInvestmentAccounts
          : state.filters.excludedInvestmentAccounts,
      },
      activeStrategyId: payload?.activeStrategyId
        ? payload?.activeStrategyId
        : state.filters.activeStrategyId,
    }),

    [actions.filterSsids]: (state, { payload }) => ({
      ...state,
      filters: {
        ...state.filters,
        excludedSsids: payload,
      },
    }),

    [actions.filterExternalReporting]: (state, { payload }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          externalReporting: filterExternalReportingHandler(payload, state),
        },
      };
    },

    [actions.filterTaxClass]: (state, { payload }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          excludedTaxClasses: filterTaxClassHandler(payload, state),
        },
      };
    },

    [actions.filterInvestmentAccount]: (state, { payload }) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          excludedInvestmentAccounts: filterInvestmentAccountHandler(
            payload,
            state
          ),
        },
      };
    },

    [actions.defaultFiltersLoaded]: (state, { payload }) => ({
      ...state,
      filters: payload ? payload : state.filters,
      defaultFilters: payload,
    }),

    [actions.defaultFiltersSave]: (state) => ({
      ...state,
      defaultFilters: state.filters,
    }),

    [actions.defaultFiltersDelete]: (state) => ({
      ...state,
      defaultFilters: null,
    }),

    [actions.activeStrategy]: (state, { payload }) => ({
      ...state,
      activeStrategyId: payload,
    }),

    [actions.storePortfolioTimestamps]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },

    [actions.flush]: (state) => defaultState,
    [actions.initialized]: (state) => ({
      ...state,
      initialized: true,
    }),
  },
  defaultState
);
