import { call, put, delay, takeLatest, select } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';
import camelcase from 'camelcase';

import { transactions } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { t } from '../../../services/localization/';
import { activeFiltersSelector } from '../../ui/portfolio/selectors';
import { transformActiveFilters, waitFor } from '../../../services';

// Create actions and a reducer for the network resource
export const [actions, reducer, resourceSagas] =
  resourceReducer('transactions');

// Create the sagas for this entity
function* readTransactions() {
  yield takeLatest(actions.indexRequestBegin, function* () {
    yield call(waitFor, (state) => state.ui.portfolio.initialized);
    const activeFilters = yield select(activeFiltersSelector);
    const args = transformActiveFilters(activeFilters);

    try {
      // Always load the full amount of transactions, don't respect the period filter done
      // on the other portfolio related APIs.
      const result = yield call(transactions.get, args);

      // We get transactions with norwegian values in the "type" field, so before we
      // process these anymore we want to go through an translate all the types values
      result.data = result.data.map((transaction) => {
        // Some transactions type includes special characters like "( + ) foo ( - )", and to create a
        // a good key for i18n we remove any non words from the type. It also removes æøå so we need
        // to be careful to make the keys in the json files match that
        transaction.attributes.type = t(
          `app:globals:transactionClass:${camelcase(
            transaction.attributes.type
          ).replace(/\W+/gi, '')}`
        );
        return transaction;
      });

      const normalized = normalize(result);
      yield put(actions.indexRequestSuccess(normalized));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [readTransactions, ...resourceSagas];
