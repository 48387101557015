import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { t } from '@formue-app/core';

import { H4Size, H4LineHeight } from '../../constants/text';
import { textLink } from '../../constants/colors';
import { SPACING_8 } from '../../constants/spacing';

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: ${H4Size};
  line-height: ${H4LineHeight};
  font-weight: 500;
  color: ${textLink};
  margin-bottom: ${SPACING_8};
`;

/**
 * Used to handle adding/removing/editing a set of "sub-forms" inside a form.
 */
export const Formset = (props) => {
  const {
    form: Component,
    defaults = {},
    initital = 1,
    setData,
    data: dataSet,
    addable = false,
    addLabel = `+ ${t('app:globals:add')}`,
    style,
  } = props;

  useEffect(() => {
    if (!dataSet) {
      const initialDataSet = Array.from(Array(initital)).map(() => defaults);
      setData(initialDataSet);
    }
  }, [dataSet, defaults, initital, setData]);

  const addForm = (event) => {
    event.preventDefault();
    setData([...dataSet, { ...defaults }]);
  };

  const onDelete = (index) => {
    const copy = [...dataSet];
    copy.splice(index, 1);
    setData(copy);
  };

  const onFormChange = (data, index) => {
    const copy = [...dataSet];
    copy[index] = data;
    setData(copy);
  };

  if (!dataSet) {
    return null;
  }

  return (
    <>
      <>
        {dataSet.map((data, index) => (
          <Component
            key={`form-${index}`}
            data={data}
            list={dataSet}
            index={index}
            style={style}
            onDelete={(event) => {
              event.preventDefault();
              onDelete(index);
            }}
            onChange={onFormChange}
          />
        ))}
      </>
      {addable && (
        <StyledLink onClick={addForm} to="">
          {addLabel}
        </StyledLink>
      )}
    </>
  );
};
