import { createSelector } from 'reselect';
import { groupBy, sumBy, flatMapDeep, filter } from 'lodash';
import camelcase from 'camelcase';

import { t } from '../../../services/localization';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [wealthBalanceAllIds, wealthBalanceById] =
  resourceSelectors('wealthBalance');

export const wealthBalanceByGroupSelector = createSelector(
  wealthBalanceById,
  (items) => {
    return groupBy(items, 'group');
  }
);

export const wealthBalanceByCategorySelector = createSelector(
  wealthBalanceById,
  (items) => {
    const excludeNull = filter(items, 'category');
    return groupBy(excludeNull, 'category');
  }
);

export const createWealthBalanceByCategorySelector = (category) => {
  return createSelector(wealthBalanceByCategorySelector, (items) => {
    return {
      name: t(
        `app:wealthBalance:categories:${camelcase(category.replace(/ /g, ''))}`
      ),
      amount: sumBy(items[category], 'amount'),
      items: items[category],
    };
  });
};

export const createWealthBalanceByGroupSelector = (group) => {
  return createSelector(wealthBalanceByGroupSelector, (items) => {
    return {
      name: t(`app:wealthBalance:groups:${camelcase(group.replace(/ /g, ''))}`),
      amount: sumBy(items[group], 'amount'),
      items: items[group],
    };
  });
};

export const totalWealthBalanceSelector = createSelector(
  wealthBalanceByCategorySelector,
  createWealthBalanceByGroupSelector('Liability'),
  (categories, debt) => {
    // TODO - Make sure that we remove the correct amount of debt. If debt is included in the
    // categories dataset, we need to remove amount twice, but for debt coming from the
    // asset and liability in SF it's not included becuase the "category" is missing
    // and that is filtered out by the "excludeNull" filter in the wealthBalanceByCategorySelector.
    return sumBy(flatMapDeep(categories), 'amount') - debt.amount;
  }
);

export const wealthBalanceHasContentSelector = createSelector(
  wealthBalanceAllIds,
  (wealthBalance) => {
    return !![...wealthBalance].length;
  }
);
