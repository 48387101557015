import { call, put, takeEvery } from 'redux-saga/effects';
import normalize from 'jsonapi-normalizer';

import { configuration } from '../../../api';
import { resourceReducer } from '../../utils/resourceReducer';
import { updateGlobalConfiguration } from '.././../../services/GlobalConfiguration';

export const [actions, reducer, ...resourceSagas] = resourceReducer(
  'configuration',
  {
    // We don't want this to flush when the APP/FLUSH event is dispatched since
    // we always want config to exist
    flushOnAppFlush: false,
  }
);

function* setConfiguration() {
  yield takeEvery(actions.indexRequestBegin, function* ({ payload }) {
    const { args } = payload;
    try {
      const result = yield call(configuration.get, args);
      const normalized = normalize(result);

      // Convert entities to something we can insert into the global configuration object
      const config = normalized?.result?.configuration.reduce(
        (accumulator, id) => {
          const { id: _, ...rest } = normalized?.entities?.configuration[id];
          return {
            ...accumulator,
            ...{ [id]: { ...rest } },
          };
        },
        {}
      );
      updateGlobalConfiguration(config);

      yield put(actions.indexRequestSuccess({ ...normalized, args }));
    } catch (error) {
      yield put(actions.indexRequestFailure({ error, args }));
      yield put({ type: 'ERRORS/HANDLE_API_ERROR', payload: error });
    }
  });
}

export const sagas = [setConfiguration, ...resourceSagas];
