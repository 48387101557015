import { useDispatch, useSelector } from 'react-redux';

import { t } from '../../../services/localization';
import { selectors, actions } from '../../../store';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

const { investmentAccountsSelector } = selectors.entities.filterValues;

export const usePortfolioInvestmentAccountsFilter = () => {
  const dispatch = useDispatch();
  const investmentAccounts = useSelector(investmentAccountsSelector);

  // We want to exclude closed investment accounts so filter out closed ones
  const activeInvestmentAccounts = investmentAccounts.filter(
    (a) => a.status !== 'closed'
  );
  const closedInvestmentAccounts = investmentAccounts.filter(
    (a) => a.status === 'closed'
  );

  // Make sure list is unique
  const uniqueInvestmentAccountsNames = [
    ...new Set(investmentAccounts.map((a) => a.name)),
  ];
  const uniqueActiveInvestmentAccountNames = [
    ...new Set(activeInvestmentAccounts.map((a) => a.name)),
  ];

  const excludedInvestmentAccounts = useSelector(
    (state) => state.ui.portfolio.filters.excludedInvestmentAccounts
  );

  if (!uniqueInvestmentAccountsNames.length) return [];

  // Helper function to determine if a toggle switch can be disabled or not.
  // if all except one is excluded already, one should not be allowed to disable
  // the last one before another is enabled. The data source requires at least
  // one account to make any sense.
  const canChange = (account) => {
    // Determine the count of excluded closed accounts. We only really care to know if
    // any of the closed accounts are excluded (E.g Terminated accounts is excluded)
    const numberOfExcludedClosedAccounts = excludedInvestmentAccounts.filter(
      (excludedAccount) =>
        closedInvestmentAccounts.some(
          (closedAccount) => closedAccount.name === excludedAccount
        )
    ).length;

    // Count excluded investment accounts, including a merged closed account if applicable
    const numberOfExcludedInvestmentAccounts =
      excludedInvestmentAccounts.length -
      numberOfExcludedClosedAccounts +
      (numberOfExcludedClosedAccounts > 0 ? 1 : 0);

    // Determine if all but one account is excluded
    const allButOneExcluded =
      numberOfExcludedInvestmentAccounts + 1 >=
      uniqueActiveInvestmentAccountNames.length +
        (closedInvestmentAccounts.length ? 1 : 0);

    // Allow toggling if not all accounts are excluded or if the account is already excluded
    return !allButOneExcluded || excludedInvestmentAccounts.includes(account);
  };

  const options = uniqueActiveInvestmentAccountNames.map(
    (investmentAccount) => ({
      name: investmentAccount,
      disabled: !canChange(investmentAccount),
      selected: !excludedInvestmentAccounts.includes(investmentAccount),
      onClick: () =>
        dispatch(uiPortfolioActions.filterInvestmentAccount(investmentAccount)),
    })
  );

  // if we have any closed investment accounts we append one option to the list
  // that we can handle differently
  const closedInvestmentAccountsNames = [
    ...new Set(closedInvestmentAccounts.map((a) => a.name)),
  ];

  if (closedInvestmentAccountsNames.length) {
    options.push({
      name: t('app:filters:closedInvestmentAccounts'),
      disabled: !canChange(closedInvestmentAccountsNames[0]), // if one of the closed ones are disabled, all are
      selected: !excludedInvestmentAccounts.includes(
        closedInvestmentAccountsNames[0]
      ),
      status: 'closed',
      onClick: () =>
        dispatch(
          uiPortfolioActions.filterInvestmentAccount(
            closedInvestmentAccountsNames
          )
        ),
    });
  }

  return options;
};
