import { supportedLocales } from './config';

export const loadTranslations = (language, namespace) => {
  if (language === 'se') {
    language = 'sv';
  }

  const resource = supportedLocales[language].translationFileLoader();
  return resource[namespace] || {};
};
