import { createSelector } from 'reselect';

import { formatMoney, percentage, t, config } from '@formue-app/core';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  costReportsAllIds,
  costReportsById,
  allCostReportsSelector,
  costReportsMetadataSelector,
] = resourceSelectors('costReports');

function getFeeName(feeCode) {
  return t(`app:portfolio:costReport:feeName:${feeCode}`, {
    companyName: config.title,
  });
}

export const productCostItemsSelector = createSelector(
  allCostReportsSelector,
  costReportsMetadataSelector,
  (items, meta) =>
    items
      .filter((item) => item.type === 'product-cost')
      .map((item) => ({
        ...item,
        name: getFeeName(item.feeCode),
        rate: percentage(item.rate * 100, 2),
        amount: formatMoney(item.amount),
      }))
      .concat([
        {
          type: 'product-cost',
          name: t('app:portfolio:costReport:table:totalProductCost'),
          rate: percentage(meta.rateProductCost * 100, 2),
          amount: formatMoney(meta.sumProductCost),
        },
      ])
);

function getPercentage(entry) {
  if (entry.multipleRates) {
    return entry.multipleRates
      .map((rate) => percentage(rate * 100, 2, 2))
      .join(' / ');
  }
  return percentage(entry.rate * 100, 2);
}

export const recurringFeeItemsSelector = createSelector(
  allCostReportsSelector,
  costReportsMetadataSelector,
  (items, meta) =>
    items
      .filter((item) => item.type === 'recurring-fee')
      .map((item) => ({
        ...item,
        name: getFeeName(item.feeCode),
        rate: getPercentage(item),
        amountExVat: formatMoney(item.amountExVat),
        vatAmount: formatMoney(item.vatAmount),
        amount: formatMoney(item.amount),
      }))
      .concat([
        {
          type: 'recurring-fee',
          name: t('app:portfolio:costReport:table:yearlyFee'),
          amount: formatMoney(meta.sumRecurringFee),
        },
      ])
);

export const oneTimeFeeItemsSelector = createSelector(
  allCostReportsSelector,
  costReportsMetadataSelector,
  (items, meta) =>
    items
      .filter((item) => item.type === 'onetime-fee')
      .map((item) => ({
        ...item,
        name: getFeeName(item.feeCode),
        rate: getPercentage(item),
        amountExVat: formatMoney(item.amountExVat),
        vatAmount: formatMoney(item.vatAmount),
        amount: formatMoney(item.amount),
      }))
      .concat([
        {
          type: 'onetime-fee',
          name: t('app:portfolio:costReport:table:oneTimeFee'),
          amount: formatMoney(meta.sumOnetimeFee),
        },
      ])
);

export const reportingFeeItemsSelector = createSelector(
  allCostReportsSelector,
  costReportsMetadataSelector,
  (items, meta) =>
    items
      .filter((item) => item.type === 'reporting-fee')
      .map((item) => ({
        ...item,
        name: getFeeName(item.feeCode),
        rate: getPercentage(item),
        amountExVat: formatMoney(item.amountExVat),
        vatAmount: formatMoney(item.vatAmount),
        amount: formatMoney(item.amount),
      }))
      .concat([
        {
          type: 'reporting-fee',
          name: t('app:portfolio:costReport:table:reportingFee'),
          amount: formatMoney(meta.sumReportingFee),
        },
      ])
);

function getNameWithAccount(entry) {
  let name = t(`app:portfolio:costReport:feeName:${entry.feeCode}`);
  if (entry.account) {
    name += ` (${entry.account})`;
  }
  return name;
}

export const utmostFeeItemsSelector = createSelector(
  allCostReportsSelector,
  costReportsMetadataSelector,
  (items, meta) =>
    items
      .filter((item) => item.type === 'utmost-fee')
      .map((item) => ({
        ...item,
        name: getNameWithAccount(item),
        rate: percentage(item.rate * 100, 2),
        amount: formatMoney(item.amount),
      }))
      .concat([
        {
          type: 'utmost-fee',
          name: t('app:portfolio:costReport:table:totalUtmostFee'),
          amount: formatMoney(meta.sumUtmostFee),
        },
      ])
);

export const nordnetFeeItemsSelector = createSelector(
  allCostReportsSelector,
  costReportsMetadataSelector,
  (items, meta) =>
    items
      .filter((item) => item.type === 'nordnet-fee')
      .map((item) => ({
        ...item,
        name: getNameWithAccount(item),
        rate: percentage(item.rate * 100, 2),
        amount: formatMoney(item.amount),
      }))
      .concat([
        {
          type: 'nordnet-fee',
          name: t('app:portfolio:costReport:table:totalNordnetFee'),
          amount: formatMoney(meta.sumNordnetFee),
        },
      ])
);
