import { config } from '../../config';

export const fallback = 'en';

const translationFiles = {
  clientPortal: {
    en: require('./lang/en.json'),
    sv: require('./lang/sv.json'),
    nb: require('./lang/nb.json'),
    da: require('./lang/da.json'),
  },
  identification: {
    en: require('./lang/identification/en.json'),
    sv: require('./lang/identification/sv.json'),
    nb: require('./lang/identification/nb.json'),
    da: require('./lang/identification/da.json'),
  },
};

export const supportedLocales = {
  en: {
    name: 'English',
    translationFileLoader: () =>
      translationFiles[config.translations.lookupKey].en,
    // en is default locale in Moment
    momentLocaleLoader: () => Promise.resolve(),
  },
  sv: {
    name: 'Svenska',
    translationFileLoader: () =>
      translationFiles[config.translations.lookupKey].sv,
    momentLocaleLoader: () => Promise.resolve(),
  },
  nb: {
    name: 'Norsk',
    translationFileLoader: () =>
      translationFiles[config.translations.lookupKey].nb,
    momentLocaleLoader: () => Promise.resolve(),
  },
  da: {
    name: 'Dansk',
    translationFileLoader: () =>
      translationFiles[config.translations.lookupKey].da,
    momentLocaleLoader: () => Promise.resolve(),
  },
};

export const supportedLanguages = Object.keys(supportedLocales);

export const defaultNamespace = 'app';

export const namespaces = ['app', 'identification'];
